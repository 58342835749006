import { FC, useEffect, useState } from 'react'
import Link from 'next/link'
import HeaderNavButton from './HeaderNavButton'
import NavLogo from './NavLogo'
import { THeaderLinkGroup, TLink } from 'common/cms-types'
import Strings from 'project/localisation'
import classNames from 'classnames'
import Accordion from 'components/Accordion'
import CompanyInformationCard from 'components/cards/CompanyInformationCard'
import Icon from 'components/Icon'
import BagIcon from './BagIcon'
import useWindowSize from 'components/util/useWindowSize'
import { Router } from 'next/router'
import { PlanSlug } from 'common/planSlugs'
import ActiveLink from './ActiveLink'
import { Project } from 'common/project'
import PreviewBar from 'components/PreviewBar'
import HotIcon from 'components/HotIcon'

type HeaderType = {}

export function officeLinks() {
  return [
    {
      href: '/membership',
      title: `${Strings.membership}`,
    },
    {
      href: '/paul-street-office',
      title: `${Strings.paulStreet}`,
    },
    {
      href: '/private-office-suites',
      title: `${Strings.privateOffices}`,
    },
  ] as TLink[]
}

export function serviceLinks() {
  return [
    {
      href: '/meeting-rooms',
      title: `${Strings.meetingRooms}`,
    },
    {
      href: '/free-business-banking',
      title: `${Strings.freeBusinessBanking}`,
    },
  ] as TLink[]
}

export function enterpriseSystemLinks() {
  return [
    {
      href: '/konnect',
      title: `${Strings.konnect}`,
    },
    {
      href: '/mailroom',
      title: `${Strings.mailroom}`,
    },
  ] as TLink[]
}

export function productLinks(addAllProducts?: boolean) {
  const links = [
    {
      hot: true,
      href: `/${PlanSlug.SCAN_LITE}`,
      title: `${Strings.virtualOfficeScanLite}`,
    },
    {
      href: `/${PlanSlug.SCAN_PRO}`,
      title: `${Strings.virtualOfficeScanPro}`,
    },
    {
      href: `/${PlanSlug.FORWARD}`,
      title: `${Strings.virtualOfficeForward}`,
    },
    {
      href: `/${PlanSlug.COLLECT}`,
      title: `${Strings.virtualOfficeCollect}`,
    },
    {
      href: `/${PlanSlug.PHONE}`,
      title: `${Strings.virtualOfficePhone}`,
    },
  ] as TLink[]
  if (addAllProducts) {
    links.unshift({
      href: '/virtual-office-london-old-street',
      title: `${Strings.allVirtualOfficePlans}`,
    })
  }
  return links
}

export function customerCareLinks() {
  return [
    {
      href: 'https://help.hoxtonmix.com/en/',
      title: `${Strings.helpAndAdvice}`,
    },
    {
      href: '/contact-us',
      title: `${Strings.contactUs}`,
    },
  ] as TLink[]
}

export function legalLinks() {
  return [
    {
      href: '/privacy-policy',
      title: `${Strings.privacyPolicy}`,
    },
    {
      href: '/terms-and-conditions',
      title: `${Strings.termsAndConditions}`,
    },
    {
      href: '/know-your-customer',
      title: `${Strings.kycRequirements}`,
    },
  ] as TLink[]
}

export function companyLinks() {
  return [
    {
      href: '/blog',
      title: `${Strings.blog}`,
    },
    {
      href: '/about-us',
      title: `${Strings.aboutUs}`,
    },
    {
      href: '/social-impact',
      title: `${Strings.socialImpact}`,
    },
    {
      href: '/affordable-workspace',
      title: `${Strings.affordableWorkspace}`,
    },
    {
      href: '/business-support',
      title: `${Strings.businessSupport}`,
    },
    {
      href: '/silicon-roundabout',
      title: `${Strings.siliconRoundabout}`,
    },
    {
      href: '/outreach',
      title: `${Strings.outreach}`,
    },
    {
      href: 'https://thehoxtonmix.statuspage.io/',
      title: `${Strings.uptimeAndStatus}`,
    },
    {
      href: '/mobile',
      title: `${Strings.hoxtonMixApp}`,
    },
  ] as TLink[]
}

function headerLinks() {
  const links = [
    {
      groups: [
        {
          links: productLinks(),
          title: `${Strings.products}`,
        },
        {
          links: serviceLinks(),
          title: Strings.services,
        },
      ],
      href: '/virtual-office-london-old-street',
      title: `${Strings.virtualOffice}`,
    },
    {
      groups: [
        {
          links: [enterpriseSystemLinks()[0]],
          title: `${Strings.partnerAPI}`,
        },
        {
          links: [enterpriseSystemLinks()[1]],
          title: `${Strings.mailroomOutsourcing}`,
        },
      ],
      title: `${Strings.enterpriseSystems}`,
    },
    {
      groups: [
        {
          links: officeLinks(),
        },
      ],
      title: `${Strings.officeSpace}`,
    },
    Project.flags.market_place && {
      href: '/marketplace',
      title: `${Strings.marketPlace}`,
    },
    {
      groups: [
        {
          links: companyLinks(),
        },
      ],
      title: `${Strings.company}`,
    },
  ] as THeaderLinkGroup[]

  return links.filter((link) => !!link)
}

export function mobileHeaderLinks() {
  return [
    {
      groups: [
        {
          links: productLinks(true),
          title: Strings.products,
        },

        {
          links: serviceLinks(),
          title: Strings.services,
        },
      ],
      title: Strings.virtualOffice,
    },
    {
      groups: [
        {
          links: enterpriseSystemLinks(),
          title: null,
        },
      ],
      title: Strings.enterpriseSystems,
    },
    {
      groups: [
        {
          links: officeLinks(),
          title: null,
        },
      ],
      title: Strings.officeSpace,
    },
    {
      groups: [
        {
          links: companyLinks(),
          title: null,
        },
      ],
      title: Strings.company,
    },
  ] as THeaderLinkGroup[]
}
const loadChargbee = (onLoad: () => void) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = 'https://js.chargebee.com/v2/chargebee.js'
  script.setAttribute('data-cb-site', Project.chargbeeSite)
  script.setAttribute('data-cb-domain', Project.chargebeeUrl)
  script.setAttribute('data-cb-gtm-enabled', 'true')
  script.setAttribute('data-cfasync', 'false')
  script.onload = onLoad
  document.head.appendChild(script)
}
let _cbInstance

const Header: FC<HeaderType> = ({}) => {
  const [collapsed, setCollapsed] = useState(true)

  const size = useWindowSize()

  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      setCollapsed(true)
    })
  }, [])

  useEffect(() => {
    loadChargbee(() => {
      const cbInstance =
        _cbInstance ||
        Chargebee.init({
          // your test site
          domain: Project.chargebeeUrl,
          site: Project.chargbeeSite, // this is an optional parameter.
          // Use this, if custom domain is enabled for your site
        })

      _cbInstance = cbInstance
    })
  }, [])

  const onLogin = () => {
    if (Project.auth.cognito) {
      document.location.href = Project.portalURL
    } else {
      Chargebee.getInstance().createChargebeePortal().open()
    }
  }
  return (
    <>
      <nav className='navbar navbar-expand-xl'>
        <div className='container'>
          <div className='mobile-nav-items'>
            <ActiveLink
              aria-label={Strings.checkout}
              className='a-unstyled'
              prefetch={false}
              href='/checkout'
            >
              <span className='nav-item nav-link-title cursor-pointer position-relative d-xl-none d-sm-block'>
                <Icon width={20} height={20} name='basket' />
                <span
                  className='badge position-absolute bottom-0 start-100 translate-middle'
                  data-test='cart-count'
                >
                  <BagIcon />
                </span>
              </span>
            </ActiveLink>
            <Link aria-label={Strings.home} className='navbar-brand' href='/'>
              <NavLogo className='me-xl-5 me-0' />
            </Link>
            <button
              onClick={() => {
                setCollapsed(!collapsed)
              }}
              className='navbar-toggler'
              type='button'
              aria-label={Strings.toggleNav}
            >
              {!collapsed ? (
                <Icon name='close' />
              ) : (
                <Icon name='hamburger-menu' />
              )}
            </button>
          </div>
          <div
            className={classNames('navbar-collapse', { collapse: collapsed })}
            id='navbarNav'
          >
            {/*Desktop Nav Items*/}
            {size.isXlOrHigher && (
              <div className='d-none d-xl-block'>
                <ul className='navbar-nav flex-row me-auto'>
                  {headerLinks().map((headerLinkGroup, i) => (
                    <HeaderNavButton
                      key={i}
                      headerLinkGroup={headerLinkGroup}
                    />
                  ))}
                </ul>
              </div>
            )}

            {size.isXlOrHigher && (
              <div className='ms-auto d-none d-xl-block'>
                <span onClick={onLogin} className='a-unstyled'>
                  <span className='nav-item nav-link-title cursor-pointer'>
                    <Icon
                      width={20}
                      height={20}
                      name='account'
                      className='me-2'
                    />
                    {Strings.login}
                  </span>
                </span>
                <Link
                  aria-label={Strings.checkout}
                  className='a-unstyled'
                  prefetch={false}
                  href='/checkout'
                >
                  <span className='nav-item nav-link-title cursor-pointer position-relative px-0 mx-0'>
                    <Icon width={20} height={20} name='basket' />
                    <span className='badge position-absolute bottom-0 start-100 translate-middle'>
                      <BagIcon />
                    </span>
                  </span>
                </Link>
              </div>
            )}

            {/*Mobile Nav Items*/}
            {!collapsed && !size.isXlOrHigher && (
              <div className='d-block d-xl-none color-white'>
                <div className='mobile-menu-container'>
                  <Accordion
                    chevronColour='#fff'
                    items={mobileHeaderLinks().map((v) => ({
                      body: (
                        <>
                          {v.groups.map((group) => (
                            <>
                              {!!group.title && (
                                <div className='text-bold d-block pb-md-2 ps-3'>
                                  {group.title}
                                </div>
                              )}
                              {group.links.map((link) => (
                                <Link
                                  className='a-unstyled'
                                  key={link.title}
                                  href={link.href}
                                >
                                  <span className='link d-flex align-items-center cursor-pointer pb-md-2 ps-3'>
                                    {link.title}
                                    {link.hot && <HotIcon />}
                                  </span>
                                </Link>
                              ))}
                            </>
                          ))}
                        </>
                      ),
                      title: `${v.title}`,
                    }))}
                  />
                  {Project.flags.market_place && (
                    <div className='collapsible pt-3 pb-3'>
                      <Link className='a-unstyled' href={'/marketplace'}>
                        <span className='text-light cursor-pointer text-bold text-link-white'>
                          {Strings.marketPlace}
                        </span>
                      </Link>
                    </div>
                  )}
                  <div className='collapsible pt-3 pb-3'>
                    <Link className='a-unstyled' href={'/about-us'}>
                      <span className='text-light cursor-pointer text-bold text-link-white'>
                        {Strings.aboutUs}
                      </span>
                    </Link>
                  </div>
                  <div className='collapsible pt-3 pb-3'>
                    <span onClick={onLogin} className='a-unstyled'>
                      <span className='text-light cursor-pointer text-bold text-link-white'>
                        <Icon
                          width={20}
                          height={20}
                          name='account'
                          className='me-2'
                        />
                        {Strings.login}
                      </span>
                    </span>
                  </div>
                  <CompanyInformationCard className='mt-3' isLight={true} />
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
      <PreviewBar />
    </>
  )
}

export default Header
